@import "./scss/scss.scss";
@import url(https://maxcdn.bootstrapcdn.com/font-awesome/4.4.0/css/font-awesome.min.css);

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

@font-face {
  font-family: "Blair";
  src: local("Blair"), url("./fonts/Blair.ttf") format("truetype");
}

// HEADER COMPONENT

.header-container {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  text-align: center;
  padding-top: 25px;
}

.contact-container {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;

  @include media-query($small) {
    flex-direction: column;
    gap: 25px;
  }
}

.call-now {
  font-family: $regular;

  @include media-query($small) {
    width: 200px;
    font-size: 1rem;
  }
}

.address {
  font-family: $regular;
  width: 150px;
  line-height: 1.2rem;
  cursor: pointer;
  @include media-query($small) {
    font-size: 1rem;
    width: 200px;
  }
}

.logo-container {
  margin-right: 50px;
  @include media-query($small) {
    order: -1;
    padding-bottom: 15px;
    margin-right: 0px;
  }
}

.logo {
  height: 58px;
  width: 213px;
}

.nav-container {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding-top: 50px;
  width: 100%;
  height: 50px;
  font-size: 1.5rem;
  font-family: $links;
}

.link {
  cursor: pointer;
  position: relative;
  transition-timing-function: cubic-bezier(0.25, 0.8, 0.25, 1);
  transition-duration: 400ms;
  transition-property: color;
  border: none;
  background: none;
}

.link:focus,
.link:hover {
  color: $headsupmain;
}

.link:focus:after,
.link:hover:after {
  width: 100%;
  left: 0%;
}

.link:after {
  content: "";
  pointer-events: none;
  bottom: -2px;
  left: 50%;
  position: absolute;
  width: 0%;
  height: 2px;
  background-color: $headsupmain;
  transition-timing-function: cubic-bezier(0.25, 0.8, 0.25, 1);
  transition-duration: 400ms;
  transition-property: width, left;
}

.link:active {
  color: red;
}
.animated {
  -webkit-animation-duration: 0.4s;
  animation-duration: 0.4s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

// LANDING PAGE COMPONENT

// TOP SECTION PHOTO CONTAINER
.top-container {
  position: relative;
}

.top-img-container {
  background-image: url("../src/assets/images/banner.png");
  background-size: cover;
  background-position: 50% 20%;
  // filter: brightness(35%) blur(2px);
  height: 700px;
  width: 100%;
  margin-top: 25px;
  box-shadow: 0px 5px 10px #606060;
}

.heading {
  font-size: 3rem;
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  color: $snow-white;
  font-family: "Blair";
  width: 900px;
  text-align: center;
  line-height: 3.5rem;

  @include media-query($small) {
    width: 100%;
    font-size: 2rem;
    line-height: 2.5rem;
  }
}

.paragraph {
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  color: $snow-white;
  font-family: $regular;
  font-size: 1.5rem;
  width: 900px;
  text-align: center;
  line-height: 2rem;

  @include media-query($small) {
    width: 100%;
    font-size: 1rem;
    line-height: 1.5rem;
    padding: 0 15px;
  }
}

// SERVICES SECTION
.services-header-img-wrapper {
    height: 450px;
  width: 100%;
  position: relative;
}
.services-header-img {
  border-radius: 10px;
  background-image: url("../src/assets/images/services-img-2.jpeg");
  background-size: cover;
  background-position: 25% 75%;
  height: 450px;
  width: 100%;
  
  filter: brightness(55%);

  @include media-query($small) {
    margin-bottom: 15%;
  }
}

.services-header-txt {
  font-size: 3rem;
  font-family: "blair";
  color: white;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;

  @include media-query($small) {
    font-size: 2rem;
    width: 100%;
    text-align: center;
   
  }

}

.h1 {
  font-family: "Blair";
  font-size: 3rem;
  text-align: center;
  padding-top: 50px;
  width: 500px;
  margin: 0 auto;

  @include media-query($small) {
    width: 100%;
    font-size: 2rem;
    line-height: 2.5rem;
  }
}

.service-info {
  font-size: 1.5rem;
  text-align: center;
  padding: 25px;
  width: 700px;
  margin: 0 auto;
  line-height: 2rem;
  font-family: $regular;

  @include media-query($small) {
    width: 100%;
    font-size: 1rem;
    line-height: 2.5rem;
  }
}

.icon-container {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  font-family: $regular;
  padding: 25px;
  padding-left: 65px;
  @include media-query($small) {
    display: flex;
    flex-direction: column;
    gap: 45px;
    text-align: center;
    padding-left: 25px;
  }
}

.service-img {
  height: 100px;
  width: auto;
}

.service-img-container {
  display: flex;
  justify-content: center;
}

.button-div {
  text-align: center;
}

.btn-link {
  --color: #006f92;
  font-family: inherit;
  display: inline-block;
  width: 8em;
  height: 3em;
  margin-top: 20px;
  margin-bottom: 150px;
  position: relative;
  overflow: hidden;
  border: 2px solid var(--color);
  transition: color 0.5s;
  z-index: 1;
  font-size: 17px;
  border-radius: 6px;
  font-weight: 500;
  color: black;
  font-family: $regular;
  cursor: pointer;
}

.btn-link:before {
  content: "";
  position: absolute;
  z-index: -1;
  background: var(--color);
  height: 150px;
  width: 200px;
  border-radius: 50%;
}

.btn-link:hover {
  color: #ffffff;
}

.btn-link:before {
  top: 100%;
  left: 100%;
  transition: all 0.4s;
}

.btn-link:hover:before {
  top: -30px;
  left: -30px;
}

.btn-link:active:before {
  background: #006f92;
  transition: background 0s;
}

//ABOUT SECTION

.about-section-container {
  width: 100%;
  height: 700px;
  background-color: #f0f2f5;
  display: flex;
  flex-wrap: wrap;

  @include media-query($small) {
    display: block;
    height: auto;
  }
}

.about-us-h1 {
  width: 100%;
  font-family: "Blair";
  font-size: 3rem;
  text-align: center;
  padding: 25px;

  @include media-query($small) {
    width: 100%;
    font-size: 2rem;
    line-height: 2.5rem;
  }
}

.outside-img-container {
  width: 50%;
  padding: 25px 55px;
}

.outside-img {
  height: 449px;
  width: 600px;
}

.about-txt-container {
  width: 50%;
  padding: 25px 55px;
  font-family: $regular;
  font-size: 1.5rem;
  line-height: 2rem;
  justify-content: center;
  text-align: center;
  display: flex;
  align-items: center;

  @include media-query($small) {
    width: 100%;
    font-size: 1rem;
  }
}

.stepper {
  height: auto;
  width: 50%;
  display: flex;
  justify-content: center;

  @include media-query($small) {
    width: 100%;
  }
}

// TESTIMONIALS

.testimonials-section {
  display: flex;
  flex-wrap: wrap;
  text-align: center;
  justify-content: space-evenly;

  @include media-query($small) {
    display: block;
    width: 100%;
  }
}

.testimonials-txt {
  display: flex;
  justify-content: center;
  width: 100%;
  font-size: 3rem;
  font-family: "Blair";
  padding: 100px;

  @include media-query($small) {
    font-size: 2rem;
  }
}

.card {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
  color: #9e9e9e;
  display: inline-block;
  font-family: "Roboto", Arial, sans-serif;
  font-size: 16px;
  margin: 35px 10px 10px;
  max-width: 310px;
  min-width: 250px;
  position: relative;
  text-align: center;
  width: 100%;
  background-color: #ffffff;
  border-radius: 5px;
  border-top: 5px solid $headsupmain;
  margin-bottom: 100px;
}

.card *,
.card *:before {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: all 0.1s ease-out;
  transition: all 0.1s ease-out;
}

.card figcaption {
  padding: 13% 10% 12%;
}

.card figcaption:before {
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  background-color: #fff;
  border-radius: 50%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
  color: $headsupmain;
  content: "\f10e";
  font-family: "FontAwesome";
  font-size: 32px;
  font-style: normal;
  left: 50%;
  line-height: 60px;
  position: absolute;
  top: -30px;
  width: 60px;
}

.card h3 {
  color: #3c3c3c;
  font-size: 20px;
  font-weight: 300;
  line-height: 24px;
  margin: 10px 0 5px;
}

.card h4 {
  font-weight: 400;
  margin: 0;
  opacity: 0.5;
}

.card blockquote {
  font-style: italic;
  font-weight: 300;
  margin: 0 0 20px;
}

// CONTACT SECTION

.contact-section-container {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  text-align: center;
  background-color: #f0f2f5;
  justify-content: center;
  @include media-query($small) {
    display: block;
    padding: 0 20px;
  }
}

.contact-us {
  font-size: 3rem;
  font-family: "Blair";
  width: 100%;
  padding: 100px;

  @include media-query($small) {
    font-size: 2rem;
  }
}

.call-txt {
  font-size: 1.5rem;
  font-family: $regular;
  width: 100%;
}

.phone-number {
  font-size: 1.2rem;
  font-family: $regular;
  width: 100%;
  padding: 45px;
}

.send-message-txt {
  font-size: 1.2rem;
  font-family: $regular;
  width: 100%;
  padding: 45px;
}

.form-container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 25px;

  @include media-query($small) {
    display: block;
  }
}

.form-btn {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 50px;

  @include media-query($small) {
    display: block;
  }
}

// SERVICES PAGE

.background {
  background-image: url("./assets/images/bg2.jpeg");
  background-size: cover;
  height: 100%;
  width: 100%;
  overflow: hidden;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
}

.services-container {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding: 100px 50px;
  gap: 50px;

  @include media-query($small) {
    padding: 100px 0px;
    gap: 0px;
  }
}

strong {
  font-weight: 800;
}

h1 {
  font-size: 2.5rem;
  font-family: "Blair";
  color: $headsupmain;
  padding-bottom: 25px;

  @include media-query($small) {
    font-size: 2rem;
    text-align: center;
  }
}

h2,
h3 {
  color: #9c710b;
  padding: 10px;
  font-size: 1.2rem;
  @include media-query($small) {
    text-align: center;
  }
}

.services-info-p {
  font-size: 1.2rem;
  font-family: $regular;
  line-height: 2.5rem;

  @include media-query($small) {
    font-size: 1rem;
    line-height: 1.5rem;
    text-align: center;
  }
}
.services-info-container {
  width: 100%;
  display: flex;

  @include media-query($small) {
    width: 100%;
    display: block;
  }
}
.MuiCardHeader-title {
  font-size: 2.2rem !important;
  font-family: $regular !important;
  line-height: 2.5rem !important;
}

.MuiTypography-body1 {
  font-size: 1.2rem !important;
  font-family: $regular !important;
  line-height: 2rem !important;

  @include media-query($small) {
    font-size: 0.8rem !important;
  }

  @media(min-width: 375px) and (max-width: 413px) {
    font-size: 0.7rem !important;
   }
}

.MuiTypography-body2 {
  font-size: 1.2rem !important;
  font-family: $regular !important;
  line-height: 2rem !important;
}

.services-info {
  padding: 50px 0px;
  flex: 1;
  align-items: center;
  display: flex;
  flex-direction: column;

  @include media-query($small) {
    width: 100%;
    padding: 25px 0px;
    display: flex;
    flex-wrap: wrap;
    // flex-direction: column;
    justify-content: center;
  }
}

.services-info-txt-container {
  width: 90%;
  text-align: center;
}

.services-card-container {
  width: 100%;
  display: flex;
  //   flex-direction: column;
  margin: 10% auto;
  gap: 50px;

  @include media-query($small) {
    width: 600px;
    display: block;
  }
}

.service-card-info-wrapper {
  display: flex;
}

.service-card-text-left {
  width: 75%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.service-card-prices-right {
  width: 25%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.services-card {
  margin: 0 auto;
}

.MuiCardHeader-title {
  text-align: center;
}

.MuiCardContent-root {
  text-align: center;
}

.disclaimer {
  width: 25%;
  text-align: center;
  margin: 0 auto;
  line-height: 1.5rem;

  @include media-query($small) {
    display: block;
    position: inherit;
    width: 100%;
  }
}

.loader {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  position: relative;
  z-index: 100;
  margin-bottom: 150px;
  animation: rotate 1s linear infinite;
}
.loader::before,
.loader::after {
  content: "";
  box-sizing: border-box;
  position: absolute;
  inset: 0px;
  border-radius: 50%;
  border: 5px solid #fff;
  animation: prixClipFix 2s linear infinite;
}
.loader::after {
  transform: rotate3d(90, 90, 0, 180deg);
  border-color: #006f92;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes prixClipFix {
  0% {
    clip-path: polygon(50% 50%, 0 0, 0 0, 0 0, 0 0, 0 0);
  }
  50% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 0, 100% 0, 100% 0);
  }
  75%,
  100% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 100% 100%, 100% 100%);
  }
}
