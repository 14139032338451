@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,400;0,700;1,400&family=Roboto:wght@100;300;400;500;700;900&display=swap');

@mixin media-query($media-query) {
  $breakpoint-found: false;

  @each $breakpoint in $grid-breakpoints {
    $name: nth($breakpoint, 1);
    $declaration: nth($breakpoint, 2);

    @if $media-query ==$name and $declaration {
      $breakpoint-found: true;

      @media only screen and #{$declaration} {
        @content;
      }
    }
  }

  @if $breakpoint-found ==false {
    @warn 'Breakpoint "#{$media-query}" does not exist';
  }
}

@mixin clearfix() {
  &::after {
    content: '';
    display: table;
    clear: both;
  }

  // sass-lint:disable no-misspelled-properties
  *zoom: 1;
}
// html,body{
//   font-family: 'Poppins' !important;
// }

a {
    text-decoration: none !important;
    color: inherit !important;
}

//fonts
$links: 'Poppins' !important;
$regular: 'Roboto Mono';

//colors
$austin-primary:#283C8C;
$austin-tertiary-light:#B4BED2;
$austin-tertiary-dark:#030F4B;
$austin-warning:#FFD600;
$austin-error:#EB4E5F;
$austin-success:#00CB94;
$austin-highlight:#244AE3;
$austin-grey:#B4BED2;
$austin-light:#F5F7F9;
$austin-grey:#D9DFE8;
$austin-dark-grey:#6c727c;
$austin-dark-grey-trans:#6c727c40;
$austin-neutral:#ECEFF3;
$austin-dark:#333B5A;
$austin-table-grey:#EDF2F9;
$austin-primary:  rgb(229, 225, 230);
$snow-white: #FFFAFA;
$headsupmain: #006F92;

//box-shadows
$box-shadow1:0px 6px 13px -9px rgba(0, 0, 0, .5);
$box-shadow:5px -5px 20px rgba(0,0,0,0.4);

//padding / spacing
$padding-sm: 16px;
$padding-lg:24px;
$padding-xl:32px;
$width-site: 1200px;
$grid-medium: 1024px;
$grid-large: 990px;
$grid-widescreen: 1400px;
$grid-gutter: 30px;
$grid-gutter-mobile: 22px;


$small: 'small';
$medium: 'medium';
$medium-down: 'medium-down';
$medium-up: 'medium-up';
$large: 'large';
$large-down: 'large-down';
$large-up: 'large-up';
$widescreen: 'widescreen';

$light-grey-1:#f5f5f5;
$light-grey-2:#f1f1f1;
$light-grey-3:#eaeaea;
$light-grey-4:#e0e0e0;
$light-grey-5:#d0d0d0;
$light-grey-6:#c0c0c0;
$light-grey-7:#b0b0b0;
$light-grey-8:#a0a0a0;

$text:#F5F5F5;
$border:#1F1C1C;
$bg:#1A2A51;
$midbg:#2D2B2B;
$blue:#00A7B1;

$green:#43CA79;
$lg:linear-gradient(180deg, $bg, #1A4751);

$grid-breakpoints: (
  $small '(max-width: #{$grid-medium - 1})',
  $medium '(min-width: #{$grid-medium}) and (max-width: #{$grid-large - 1})',
  $medium-down '(max-width: #{$grid-large - 1})',
  $medium-up '(min-width: #{$grid-medium})',
  $large '(min-width: #{$grid-large}) and (max-width: #{$grid-widescreen - 1})',
  $large-down '(max-width: #{$grid-widescreen - 1})',
  $large-up '(min-width: #{$grid-large})',
  $widescreen '(min-width: #{$grid-widescreen})'
);

html,body, div, img{
  box-sizing: border-box;
}
.page-width {
  width: 100% !important;
  @include clearfix();
  max-width: $width-site;
  margin: 0 auto;
}
.upcase{
  text-transform: uppercase;
  font-size:.8em;
  letter-spacing: 2px;
}
a{
  text-decoration: none !important;
}
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active{
    -webkit-box-shadow: 0 0 0 30px $bg inset !important;
    input{
      color:$text !important;
    }
}

input:-webkit-autofill {
  
  -webkit-text-fill-color: $text;
}
input:-webkit-autofill:focus {
  
  -webkit-text-fill-color: $text;
}
html,body{
  margin:0;
  padding:0;

}
html{
  overflow-x:hidden !important;
}
div{
  box-sizing: border-box;
}